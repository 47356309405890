@import url('https://fonts.googleapis.com/css2?family=Mukta&family=Roboto&family=Rubik&family=Teko&display=swap');

.App {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  color: white;
  background-color: rgb(0, 7, 56);
}
.navBar {
  display: flex;
  justify-content: right;
  align-items: center;
  font-size: 1.3em;
  width: 100%;
  background: url("../src/images/diveFlag.png") no-repeat left center;
  background-size: contain;
  background-color: rgba(12, 12, 12, 0.587);
  border-bottom: 2px solid rgb(125, 0, 0);
  margin-bottom: 1.5em;
  padding-bottom: .5em;
}
.nav {
  margin-right: 1.5em;
}
.navHeader {
  font-size: 1.2em ;
}
.navLink{
  font-size: .8em;
  text-decoration: none;
  color: rgb(162, 162, 162);
  padding: 2%;
  margin: 2%;
}
.user-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: .7em;
  margin-bottom: 2em;
}
.user-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(0, 0, 255, 0.652);
  border-radius: 1em;
  box-shadow: 0em 0em 1em rgba(13, 255, 0, 0.829);
  width: 10%;
}
.user-form {
  display: flex;
  justify-content: center;
}
.user-form > form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 255, 0.237);
  border: 2px solid rgb(125, 0, 0);
  border-radius: 2em;
  box-shadow: 0em 0em 1em rgb(255, 140, 0);
  width: 30%;
  margin-bottom: 2em;
}
.create-button {
  margin: 1em;
}
.deleteButton {
  color: rgb(0, 0, 0);
  background-color: rgb(255, 0, 0);
  width: 9em;
  border-radius: .5em;
  border: none;
  font-weight: bold;
}
.user-container {
  display: flex;
  align-items: center;
}
.user-detail-container {
  display: flex;
  justify-content: space-around;
}
.user-data-container {
  display: flex;
  justify-content: center;
  margin: 2% 2% 0 2%;
}
.loadout-container, .log-container {
  border: 2px solid rgb(125, 0, 0);
  border-radius: 1em;
  box-shadow: 0em 0em 1em rgb(255, 140, 0);
  min-width: 10em;
  margin: 5% 15% 5% 15%;
  padding: 5%;
}
.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 3em;
}
.log-form, .loadout-form, .update-from {
  margin: 2em;
}
form {
  display: flex;
  flex-direction: column;
}
form > label {
  margin-top: 1em;
}
.loadout-page {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}
.gear-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  margin-bottom: 2em;
}
.gear-header {
  height: 1em;
  margin-bottom: none;
}
.gear-container{
  display: grid;
  margin-bottom: 3em;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  border: 2px solid rgba(0, 0, 255, 0.652);
  border-radius: 1em;
  box-shadow: 0em 0em 1em rgba(13, 255, 0, 0.829);
}
.gear-card {
  margin: 1.2em;
  display: flex;
  flex-direction: column-reverse;
  padding: .5em;
  border: 2px solid rgb(125, 0, 0);
  box-shadow: 0em 0em 1em rgb(255, 140, 0);
  border-radius: 1em;
}
.gear-form > label {
  margin-bottom: .7em;
}
.log-page {
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  justify-items: center;
}
.log-card {
  text-align: center;
  background-color: rgba(0, 16, 135, 0.745);
  box-shadow: 0em 0em 1em rgba(0, 255, 102, 0.73);
  margin: 3% 0 3% 0;
  border-radius: 2em;
  width: 50%;
}
.log-card > div {
  border-top: 2px dotted rgba(255, 0, 0, 0.733);
}
.back-button {
  border: 1px solid rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  background-color: transparent;
  border-radius: .3em;
}
.form-button {
  margin-top: .7em;
  background-color: rgba(153, 255, 0, 0.734);
  border: none;
  font-weight: bold;
  border-radius: .5em;
}
.gear-header {
  font-size: 2em;
  text-shadow: 3px 3px 4px red;
}
.about {
  text-align: center;
}

